import Cookies from 'js-cookie';

window.addEventListener('load', () => {
  const { carrotquest } = window;
  const {
    current_user: currentUser,
    carrotquest: { user_hash: carrotquestHash },
    fired_events: firedEventsList = {},
  } = window.gon;

  if (!carrotquest) {
    Sentry.captureMessage('Failed to load Carrotquest');
    return;
  }

  const trackableEvents = {
    signed_in: '$authorized',
    signed_out: '$logout',
  };

  const trackEvents = (events) => {
    Object.keys(trackableEvents)
      .filter((event) => Object.keys(events).includes(event))
      .forEach((event) => carrotquest.track(trackableEvents[event]));
  };

  const buildProps = (user) => {
    const {
      can_use_paid_features: canUsePaidFeatures,
      email,
      current_company: currentCompany,
      current_program: currentProgram,
    } = user;
    const ymId = Cookies.get('_ym_uid');
    const rentaId = Cookies.get('_rid_anm_id');

    const props = [
      {
        op: 'update_or_create',
        key: 'Платный доступ',
        value: canUsePaidFeatures,
      },
      email ? { op: 'update_or_create', key: '$email', value: email } : null,
      currentCompany
        ? { op: 'update_or_create', key: 'Компания', value: currentCompany }
        : { op: 'delete', key: 'Компания', value: null },
      currentProgram
        ? {
            op: 'update_or_create',
            key: 'Текущая программа',
            value: user.current_program,
          }
        : { op: 'delete', key: 'Текущая программа', value: null },
      ymId ? { op: 'update_or_create', key: '_ym_uid', value: ymId } : null,
      rentaId
        ? { op: 'update_or_create', key: '_rid_anm_id', value: rentaId }
        : null,
    ];

    return props.filter((prop) => prop);
  };

  const handleChatVisibility = (user) => {
    const shouldShowChat = window.location.search.includes('chat=1');
    if (
      user.current_company &&
      !shouldShowChat &&
      typeof carrotquest.removeChat === 'function'
    ) {
      carrotquest.removeChat();
    }
  };

  const props = buildProps(currentUser);

  if (currentUser.is_guest) {
    trackEvents(firedEventsList);
  } else {
    carrotquest.auth(currentUser.id.toString(), carrotquestHash);
    trackEvents(firedEventsList);
    handleChatVisibility(currentUser);
  }
  carrotquest.identify(props);
});
